import { captureRemixErrorBoundaryError } from "@sentry/remix";
import { LinksFunction, LoaderFunction, json } from "@remix-run/node";
import {
  Links,
  Meta,
  Outlet,
  Scripts,
  useLoaderData,
  useRouteError,
} from "@remix-run/react";
import stylesheet from "./tailwind.css?url";
import agGridCoreStylesheet from "ag-grid-enterprise/styles/ag-grid.css?url";
import agGridQuartzStylesheet from "ag-grid-enterprise/styles/ag-theme-quartz.css?url";
import { Theme, ThemeProvider, useTheme } from "./utils/theme";
import { SessionManager } from "./.server/services/session";
import clsx from "clsx";

export type LoaderData = {
  theme: Theme | null;
  env: {
    AG_GRID_ENTERPRISE_KEY: string;
  };
};

export const loader: LoaderFunction = async ({ request }) => {
  const themeSession = await SessionManager.fromRequest(request);

  const data: LoaderData = {
    theme: themeSession.getTheme(),
    env: {
      AG_GRID_ENTERPRISE_KEY: process.env.AG_GRID_ENTERPRISE_KEY,
    },
  };

  return json(data);
};

export const links: LinksFunction = () => [
  { rel: "stylesheet", href: stylesheet as any },
  { rel: "stylesheet", href: "https://rsms.me/inter/inter.css" },
  { rel: "stylesheet", href: agGridCoreStylesheet },
  { rel: "stylesheet", href: agGridQuartzStylesheet },
];

function App() {
  const [theme] = useTheme();
  const data = useLoaderData<LoaderData>();
  return (
    <html suppressHydrationWarning className={`h-full ${clsx(theme)}`}>
      <head>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />

        <Meta />
        <Links />
      </head>
      <body suppressHydrationWarning className="h-full dark:bg-gray-950">
        <Outlet />
        <script
          dangerouslySetInnerHTML={{
            __html: `window.env = ${JSON.stringify(data.env)}`,
          }}
        />
        <Scripts />
      </body>
    </html>
  );
}

export function ErrorBoundary() {
  const error = useRouteError();
  try {
    captureRemixErrorBoundaryError(error);
  } catch (err) {
    console.error(err);
  }
  return (
    <html>
      <head>
        <title>Oh no!</title>
        <Meta />
        <Links />
      </head>
      <body>
        {/* add the UI you want your users to see */}
        <Scripts />
      </body>
    </html>
  );
}

export default function AppWithProviders() {
  const data = useLoaderData<LoaderData>();
  return (
    <ThemeProvider specifiedTheme={data.theme}>
      <App />
    </ThemeProvider>
  );
}
